import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74')
];

export const server_loads = [0];

export const dictionary = {
		"/": [18],
		"/auth": [71],
		"/auth/verify-email": [72],
		"/error": [73],
		"/healthcheck/diagnose": [74],
		"/(login)/login": [19,[2]],
		"/(login)/sign-up": [20,[2]],
		"/(login)/sign-up/hp-verify": [21,[2]],
		"/(user)/user-home": [22,[3]],
		"/(user)/user-profile": [23,[3,4]],
		"/(user)/user-profile/account": [24,[3,4]],
		"/(user)/user-profile/appearance": [25,[3,4]],
		"/(user)/user-profile/overview": [26,[3,4]],
		"/(user)/user-profile/profile": [27,[3,4]],
		"/(user)/verify-email": [28,[3]],
		"/[community=slug]": [29,[5],[6]],
		"/[community=slug]/ai-history": [34,[5,8],[6]],
		"/[community=slug]/ai-history/[slug]": [35,[5,8],[6]],
		"/[community=slug]/(knowledge-base)/category/[slug]/[uuid]": [30,[5,7],[6]],
		"/[community=slug]/changelog": [36,[5,9],[6]],
		"/[community=slug]/changelog/[slug]/[uuid]": [37,[5,9],[6]],
		"/[community=slug]/community/forum/[...rest]": [39,[5],[6]],
		"/[community=slug]/community/[...rest]": [38,[5],[6]],
		"/[community=slug]/content-library": [40,[5,10],[6]],
		"/[community=slug]/context-sources": [41,[5],[6]],
		"/[community=slug]/demo": [42,[5,11],[6]],
		"/[community=slug]/(knowledge-base)/doc": [31,[5,7],[6]],
		"/[community=slug]/(knowledge-base)/doc/[slug]/[uuid]": [32,[5,7],[6]],
		"/[community=slug]/forums": [43,[5,12],[6]],
		"/[community=slug]/forums/community-error": [46,[5,12],[6]],
		"/[community=slug]/forums/home": [47,[5,12],[6]],
		"/[community=slug]/forums/members": [48,[5,12],[6]],
		"/[community=slug]/forums/page/[slug]/[uuid]": [49,[5,12],[6]],
		"/[community=slug]/forums/[forumSlug]/[uuid]": [44,[5,12,13],[6]],
		"/[community=slug]/forums/[forumSlug]/[uuid]/[threadSlug]/[threadUuid]": [45,[5,12,13],[6]],
		"/[community=slug]/home": [50,[5],[6]],
		"/[community=slug]/insights": [51,[5,14],[6]],
		"/[community=slug]/insights/knowledge-base": [~52,[5,14],[6]],
		"/[community=slug]/insights/workspace": [53,[5,14],[6]],
		"/[community=slug]/join": [54,[15]],
		"/[community=slug]/join/[inviteCode]": [55,[15]],
		"/[community=slug]/(knowledge-base)/section/[slug]/[uuid]": [33,[5,7],[6]],
		"/[community=slug]/settings": [56,[5,16],[6]],
		"/[community=slug]/settings/changelog/settings": [57,[5,16],[6]],
		"/[community=slug]/settings/community/custom-roles": [58,[5,16],[6]],
		"/[community=slug]/settings/community/labels": [59,[5,16],[6]],
		"/[community=slug]/settings/community/manage-members": [60,[5,16],[6]],
		"/[community=slug]/settings/community/settings": [61,[5,16],[6]],
		"/[community=slug]/settings/general/overview": [62,[5,16],[6]],
		"/[community=slug]/settings/general/team": [63,[5,16],[6]],
		"/[community=slug]/settings/general/webhooks": [64,[5,16],[6]],
		"/[community=slug]/settings/help-center/content-links": [65,[5,16],[6]],
		"/[community=slug]/settings/help-center/custom-domain": [66,[5,16],[6]],
		"/[community=slug]/settings/help-center/settings": [67,[5,16],[6]],
		"/[community=slug]/settings/widget/settings": [68,[5,16],[6]],
		"/[community=slug]/widget": [69,[17]],
		"/[community=slug]/workspace": [70,[5],[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';